/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_3j3uvOhz9',
  // localhost
  // "aws_user_pools_web_client_id": "7mjif806kppko7d8di07cgqnba",
  // it.akkad.app
  aws_user_pools_web_client_id: '4mm6sntqpbs3btbedmbu62g4b',
  oauth: {
    domain: 'tower-dev.auth.us-east-1.amazoncognito.com',
  },
};

export default awsmobile;
