import awsMobile from '../aws-exports';
import { Auth } from 'aws-amplify';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const local = {
  aws_user_pools_web_client_id: '4mm6sntqpbs3btbedmbu62g4b',
  oauth: {
    redirectSignIn: 'http://localhost:3000/',
    redirectSignOut: 'http://localhost:3000/',
  },
};

const prod = {
  aws_user_pools_web_client_id: '4mm6sntqpbs3btbedmbu62g4b',
  oauth: {
    redirectSignIn: 'https://stage-it.akkad.app/',
    redirectSignOut: 'https://stage-it.akkad.app/',
  },
};

const config = isLocalhost ? local : prod;

const awsconfig = {
  ...awsMobile,
  ...config,
  mandatorySignIn: true,
  oauth: {
    ...awsMobile.oauth,
    ...config.oauth,
    responseType: 'code',
    scope: ['openid'],
  },
  API: {
    endpoints: [
      {
        name: 'helloAPI',
        endpoint: 'https://api.stage.akkad.app',
        custom_header: async () => {
          const selectedRole = localStorage.getItem('selectedRole');

          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'X-Selected-Role': selectedRole
              ? selectedRole
              : (await Auth.currentSession()).getIdToken().payload['custom:role']?.split(',')[0] || 'user',
          };
        },
      },
    ],
  },
  aws_user_pools_web_client_id: isLocalhost ? '4mm6sntqpbs3btbedmbu62g4b' : '4mm6sntqpbs3btbedmbu62g4b',
};

export default awsconfig;
